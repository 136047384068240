import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { selectIncomeAccount, incomeAdjudication } from 'actions/actions';

import Form from 'components/Common/Form/Form';
import Header from 'components/Common/Layout/Header';
import Radio from 'components/Common/Form/Radio';
import CheckboxGroup from 'components/Common/Form/CheckboxGroup';
import Navigation from 'components/Common/Layout/Navigation';

const Relationship = styled.p`
  ${props => props.theme.main.fonts.body.bold};
  color: ${props => props.theme.main.colors.grey.base};
`;

export default React.memo(() => {  
  const dispatch = useDispatch();
  const { applicationId, cifId, relationships, incomeAccountId, creditBureauConsent, accessToken, isMobile } = useSelector(state => state.reducer);

  const submit = (event) => {
    event.preventDefault();
    dispatch(incomeAdjudication({
      cifId: cifId,
      applicationId: applicationId,
      creditBureauConsent: creditBureauConsent,
      incomeInfo: {
        accountId: incomeAccountId
      }
    }, accessToken, isMobile));    
  }

  const selectAccount = (event) => {    
    dispatch(selectIncomeAccount(event.target.value));
  }

  const back = () => {
    navigate('/requirements', { replace: true });
  }

  const canContinue = (incomeAccountId);

  return (
    <Form>
      <Header>We need to verify your income</Header>
      <CheckboxGroup label="Which account is your income deposited into?" description="By choosing an account you are giving Vancity consent to analyze transactions in your account.">
        {relationships.map((relationship, i) => (
          <div key={i}>
            {relationship.accounts.length > 0 ?
              <div>
                <Relationship>Relationship {relationship.relationshipNumber}</Relationship>
                <div>
                  {relationship.accounts.map((account, i) => (
                    <Radio key={i} name="relationship" value={account.account.id.value} label={<div><b>{account.account.product.name}</b> {account.account.id.value}<br />{new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(account.account.balance.amount)}</div>} onChange={selectAccount} selectedValue={incomeAccountId} />            
                  ))}
                </div>
              </div>
              : null}
          </div>
        ))}
      </CheckboxGroup>
      <Navigation buttonLabel="Continue" onClickForward={submit} onClickBack={back} disabled={!canContinue} />
    </Form>
  );
});
